import { DownloadOutlined }               from '@ant-design/icons';
import { IssuesCloseOutlined }            from '@ant-design/icons';
import { UserOutlined }                   from '@ant-design/icons';
import { CheckCircleOutlined }            from '@ant-design/icons';
import { ContactsOutlined }               from '@ant-design/icons';
import { HomeOutlined }                   from '@ant-design/icons';
import { CarOutlined }                    from '@ant-design/icons';
import { SettingOutlined }                from '@ant-design/icons';
import { MonitorOutlined }                from '@ant-design/icons';
import { ShoppingCartOutlined }           from '@ant-design/icons';
import ContactModel                       from 'Models/directory/ContactModel';
import EnterpriseGroupModel               from 'Models/directory/EnterpriseGroupModel';
import EnterpriseModel                    from 'Models/directory/EnterpriseModel';
import FunctionFamilyModel                from 'Models/directory/FunctionFamilyModel';
import GlobalContactModel                 from 'Models/directory/GlobalContactModel';
import AccreditationModel                 from 'Models/intervention/AccreditationModel';
import ComplianceReferentialModel         from 'Models/intervention/ComplianceReferentialModel';
import EquipmentModel                     from 'Models/intervention/EquipmentModel';
import GearModel                          from 'Models/intervention/GearModel';
import HazardModel                        from 'Models/intervention/HazardModel';
import InstallationClassificationModel    from 'Models/intervention/InstallationClassificationModel';
import InstallationTypeModel              from 'Models/intervention/InstallationTypeModel';
import MeasureGroupModel                  from 'Models/intervention/MeasureGroupModel';
import MeasureMethodologyModel            from 'Models/intervention/MeasureMethodologyModel';
import MeasureModel                       from 'Models/intervention/MeasureModel';
import ParticleClassModel                 from 'Models/intervention/ParticleClassModel';
import SampleTypeModel                    from 'Models/intervention/SampleTypeModel';
import TaskOperationExtraReasonModel      from 'Models/intervention/TaskOperationExtraReasonModel';
import AccountingAnalyticCategoryModel    from 'Models/invoice/AccountingAnalyticCategoryModel';
import AccountingAnalyticSubCategoryModel from 'Models/invoice/AccountingAnalyticSubCategoryModel';
import TalentiaImportModel                from 'Models/invoice/TalentiaImportModel';
import ChannelModel                       from 'Models/notification/ChannelModel';
import PartitionModel                     from 'Models/partition/PartitionModel';
import SubPartitionModel                  from 'Models/partition/SubPartitionModel';
import StaffModel                         from 'Models/rh/StaffModel';
import ActivityCategoryModel              from 'Models/sales/ActivityCategoryModel';
import FrameworkAgreementIterationModel   from 'Models/sales/FrameworkAgreementIterationModel';
import OriginModel                        from 'Models/sales/OriginModel';
import TermModel                          from 'Models/sales/TermModel';
import AllocationRequestModel             from 'Models/supplier/AllocationRequestModel';
import OrderPartModel                     from 'Models/supplier/OrderPartModel';
import OrderTypeModel                     from 'Models/supplier/OrderTypeModel';
import ProductTypeModel                   from 'Models/supplier/ProductTypeModel';
import PurchaseConditionModel             from 'Models/supplier/PurchaseConditionModel';
import SupplierCompanyModel               from 'Models/supplier/SupplierCompanyModel';
import SupplierEnterpriseContactModel     from 'Models/supplier/SupplierEnterpriseContactModel';
import SupplierEnterpriseModel            from 'Models/supplier/SupplierEnterpriseModel';
import SEPurchaseConditionModel           from 'Models/supplier/SupplierEnterprisePurchaseConditionModel';
import AcquisitionTypeModel               from 'Models/vehicle/AcquisitionTypeModel';
import AmenityModel                       from 'Models/vehicle/AmenityModel';
import DamageModel                        from 'Models/vehicle/DamageModel';
import DressingModel                      from 'Models/vehicle/DressingModel';
import EnergyModel                        from 'Models/vehicle/EnergyModel';
import FuelCardModel                      from 'Models/vehicle/FuelCardModel';
import GeolocationModel                   from 'Models/vehicle/GeolocationModel';
import InsuranceContractModel             from 'Models/vehicle/InsuranceContractModel';
import MaintenanceDutyModel               from 'Models/vehicle/MaintenanceDutyModel';
import MaintenancePlanModel               from 'Models/vehicle/MaintenancePlanModel';
import VehicleBrandModel                  from 'Models/vehicle/VehicleBrandModel';
import VehicleModelModel                  from 'Models/vehicle/VehicleModelModel';
import VehicleModel                       from 'Models/vehicle/VehicleModel';
import VehicleOutTypeModel                from 'Models/vehicle/VehicleOutTypeModel';
import VehicleTypeModel                   from 'Models/vehicle/VehicleTypeModel';
import VehicleVersionModel                from 'Models/vehicle/VehicleVersionModel';
import ViolationModel                     from 'Models/vehicle/ViolationModel';
import ViolationTicketModel               from 'Models/vehicle/ViolationTicketModel';
import ViolationTypeModel                 from 'Models/vehicle/ViolationTypeModel';
import { MenuItemGroupType }              from 'antd/lib/menu/hooks/useItems';
import { MenuItemType }                   from 'antd/lib/menu/hooks/useItems';
import { ItemType }                       from 'antd/lib/menu/hooks/useItems';
import Menu                               from 'antd/lib/menu';
import * as H                             from 'history';
import _uniqueId                          from 'lodash/uniqueId';
import { NavLink }                        from 'react-router-dom';
import { matchPath }                      from 'react-router';
import React                              from 'react';
import routes                             from 'routes';
import navigate                           from 'tools/navigate';

const getItem = (item: Partial<MenuItemType | MenuItemGroupType>, path?): MenuItemType => ({
	...item,
	key: path || _uniqueId(),
	label: path ? <NavLink to={path}>{item.label}</NavLink> : item.label,
});

export const getMenuItems = (): ItemType[] => [
	getItem({ icon: <HomeOutlined />, label: 'Accueil' }, navigate.toHome()),
	getItem({
		children: [
			getItem({ label: EnterpriseGroupModel.pluralStaticLabel }, EnterpriseGroupModel.pathToList),
			getItem({ label: EnterpriseModel.pluralStaticLabel }, navigate.toEnterpriseList()),
			getItem({ label: 'Établissements' }, navigate.toCompanyList()),
			getItem({ label: FrameworkAgreementIterationModel.pluralStaticLabel }, FrameworkAgreementIterationModel.pathToList),
		],
		icon: <ContactsOutlined />,
		label: 'Clients',
	}),
	getItem({
		children: [
			getItem({ label: OrderPartModel.pluralStaticLabel }, OrderPartModel.pathToList),
			getItem({ label: SupplierEnterpriseModel.pluralStaticLabel }, SupplierEnterpriseModel.pathToList),
			getItem({ label: SupplierCompanyModel.pluralStaticLabel }, SupplierCompanyModel.pathToList),
		],
		icon: <ShoppingCartOutlined />,
		label: 'Achats',
	}),
	getItem({ 
		children: [
			getItem({ label: 'Contacts clients' }, GlobalContactModel.pathToList),
			getItem({ label: SupplierEnterpriseContactModel.pluralStaticLabel }, SupplierEnterpriseContactModel.pathToList),
		],
		icon: <UserOutlined />, 
		label: ContactModel.pluralStaticLabel,
	}),
	getItem({
		children: [
			getItem({ label: `Retours` }, navigate.toInterventionTaskOperationExtraList()),
		],
		icon: <IssuesCloseOutlined />,
		label: 'QHSE',
	}),
	getItem({
		children: [
			getItem({ label: `Tableau de bord` }, navigate.toVehicleServiceDashboard()),
			getItem({ label: VehicleModel.pluralStaticLabel }, VehicleModel.pathToList),
			getItem({ label: ViolationTicketModel.pluralStaticLabel }, ViolationTicketModel.pathToList),
			getItem({ label: MaintenanceDutyModel.pluralStaticLabel }, MaintenanceDutyModel.pathToList),
			getItem({ label: DamageModel.pluralStaticLabel }, DamageModel.pathToList),
			getItem({ label: InsuranceContractModel.pluralStaticLabel }, InsuranceContractModel.pathToList),
		],
		icon: <CarOutlined />,
		label: 'Gestion du parc automobile',
	}),
	getItem({
		children: [
			getItem({ label: 'Validation des avoirs' }, navigate.toBillableCreditToValidateListPage()),
			getItem({ label: 'Sites bloqués' }, navigate.toBlockRequestList()),
			getItem({ label: 'Coordonnées bancaires' }, navigate.toDebitMandateToActivateListPage()),
			getItem({ label: SEPurchaseConditionModel.staticLabel }, SEPurchaseConditionModel.pathToList),
			getItem({ label: AllocationRequestModel.staticLabel }, AllocationRequestModel.pathToList),
			getItem({ label: 'Fournisseurs à valider' }, SupplierEnterpriseModel.pathToList + '_pending'),
		],
		icon: <CheckCircleOutlined />,
		label: 'Validations',
	}),
	getItem({
		children: [
			getItem({ label: 'Factures' }, navigate.toInvoiceList()),
			getItem({ label: 'Balance agée' }, navigate.toAgedTrialBalanceList()),
			getItem({ label: TalentiaImportModel.pluralStaticLabel + ' Talentia' }, TalentiaImportModel.pathToList),
			getItem({ label: 'Suivi facturation' }, navigate.toInvoiceItemList()),
			getItem({ label: 'Facturation' }, navigate.toStatistics()),
		],
		icon: <MonitorOutlined />,
		label: 'Statistiques',
	}),
	getItem({
		children: [
			getItem({
				children: [
					getItem({ label: 'Taux de TVA' }, navigate.toVatList()),
					getItem({ label: 'Codes comptables' }, navigate.toAccountingCodeList()),
					getItem({ label: AccountingAnalyticCategoryModel.pluralStaticLabel }, AccountingAnalyticCategoryModel.pathToList), getItem({ label: AccountingAnalyticSubCategoryModel.pluralStaticLabel }, AccountingAnalyticSubCategoryModel.pathToList),
					getItem({ label: 'Modes de paiement' }, navigate.toPaymentModeTypeList()),
					getItem({ label: 'Règles de paiement / Codes NAF' }, navigate.toPaymentModeRuleList()),
					getItem({ label: 'RFA' }, navigate.toRFAList()),
					getItem({ label: 'Formule de révision de prix' }, navigate.toPriceRevisionFormulaList()),
				],
				label: 'Finance',
			}),
			getItem({
				children: [
					getItem({ label: GearModel.staticLabel }, GearModel.pathToList),
					getItem({ label: HazardModel.pluralStaticLabel }, HazardModel.pathToList),
					getItem({ label: EquipmentModel.pluralStaticLabel }, EquipmentModel.pathToList),
				],
				label: 'Sécurité',
			}),
			getItem({
				children: [
					getItem({ label: AccreditationModel.pluralStaticLabel }, AccreditationModel.pathToList),
					getItem({ label: ParticleClassModel.pluralStaticLabel }, ParticleClassModel.pathToList),
					getItem({ label: InstallationClassificationModel.pluralStaticLabel }, InstallationClassificationModel.pathToList),
					getItem({ label: MeasureGroupModel.pluralStaticLabel }, MeasureGroupModel.pathToList),
					getItem({ label: MeasureModel.pluralStaticLabel }, MeasureModel.pathToList),
					getItem({ label: MeasureMethodologyModel.pluralStaticLabel }, MeasureMethodologyModel.pathToList),
					getItem({ label: ComplianceReferentialModel.pluralStaticLabel }, ComplianceReferentialModel.pathToList),
					getItem({ label: SampleTypeModel.pluralStaticLabel }, SampleTypeModel.pathToList),
					getItem({ label: InstallationTypeModel.pluralStaticLabel }, InstallationTypeModel.pathToList),
				],
				label: 'ACC',
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
			}),
			getItem({
				children: [
					getItem({ label: ActivityCategoryModel.pluralStaticLabel }, ActivityCategoryModel.pathToList),
					getItem({ label: OriginModel.pluralStaticLabel }, OriginModel.pathToList),
					getItem({ label: `Types de prestations` }, navigate.toActivityTypeList()),
					getItem({ label: TermModel.pluralStaticLabel }, TermModel.pathToList),
					getItem({ label: TaskOperationExtraReasonModel.pluralStaticLabel }, TaskOperationExtraReasonModel.pathToList),
				],
				label: 'Exploitation',
			}),
			getItem({
				children: [
					getItem({ label: StaffModel.pluralStaticLabel }, StaffModel.pathToList),
					getItem({ label: `Groupes d'utilisateurs` }, navigate.toRootGroupList()),
					getItem({ label: `Droits d'accès` }, navigate.toActionGroupList()),
					getItem({ label: `Actions` }, navigate.toActionList()),
					getItem({ label: `Synthèse des droits d'accès` }, navigate.toSynthesisAccessRight()),
					getItem({ label: ChannelModel.pluralStaticLabel }, ChannelModel.pathToList),
				],
				label: 'Utilisateurs',
			}),
			getItem({
				children: [
					getItem({ label: PartitionModel.pluralStaticLabel }, PartitionModel.pathToList),
					getItem({ label: SubPartitionModel.pluralStaticLabel }, SubPartitionModel.pathToList),
				],
				label: 'Sociétés',
			}),
			getItem({
				children: [
					getItem({ label: VehicleBrandModel.pluralStaticLabel }, VehicleBrandModel.pathToList),
					getItem({ label: VehicleModelModel.pluralStaticLabel }, VehicleModelModel.pathToList),
					getItem({ label: VehicleVersionModel.pluralStaticLabel }, VehicleVersionModel.pathToList),
					getItem({ label: VehicleTypeModel.pluralStaticLabel }, VehicleTypeModel.pathToList),
					getItem({ label: AcquisitionTypeModel.pluralStaticLabel }, AcquisitionTypeModel.pathToList),
					getItem({ label: MaintenancePlanModel.pluralStaticLabel }, MaintenancePlanModel.pathToList),
					getItem({ label: FuelCardModel.pluralStaticLabel }, FuelCardModel.pathToList),
					getItem({ label: GeolocationModel.pluralStaticLabel }, GeolocationModel.pathToList),
					getItem({ label: ViolationModel.pluralStaticLabel }, ViolationModel.pathToList),
					getItem({ label: ViolationTypeModel.pluralStaticLabel }, ViolationTypeModel.pathToList),
					getItem({ label: DressingModel.pluralStaticLabel }, DressingModel.pathToList),
					getItem({ label: AmenityModel.pluralStaticLabel }, AmenityModel.pathToList),
					getItem({ label: VehicleOutTypeModel.pluralStaticLabel }, VehicleOutTypeModel.pathToList),
					getItem({ label: EnergyModel.pluralStaticLabel }, EnergyModel.pathToList),
				],
				label: 'Paramétrage parc auto',
			}),
			getItem({
				children: [
					getItem({ label: OrderTypeModel.pluralStaticLabel }, OrderTypeModel.pathToList),
					getItem({ label: ProductTypeModel.pluralStaticLabel }, ProductTypeModel.pathToList),
					getItem({ label: PurchaseConditionModel.staticLabel }, PurchaseConditionModel.pathToList),
				],
				label: 'Paramétrage fournisseurs',
			}),
			getItem({
				children: [
					getItem({ label: FunctionFamilyModel.pluralStaticLabel }, FunctionFamilyModel.pathToList),
				],
				label: 'Contacts',
			}),
		],
		icon: <SettingOutlined />,
		label: 'Administration',
	}),
	getItem({ icon: <DownloadOutlined />, label: 'Téléchargements' }, navigate.toFiles()),
];

export default (props: { location: H.Location }) => {
	const selectedKeys = [];
	getCurrentSelectedKeys(props.location, selectedKeys, routes);

	return (
		<div className="Navigation__Container">
			<Menu
				className="Navigation__Menu"
				defaultOpenKeys={['subNav']}
				id="Navigation__Menu"
				items={getMenuItems()}
				mode="inline"
				selectedKeys={selectedKeys}
				theme="light"
			/>
		</div>
	);
};

const getCurrentSelectedKeys = (location: H.Location, matchedRoutes, routes) => {
	for (let i = 0; i < routes.length; i++) {
		const route = routes[i];
		const matchConfig = { exact: route.exact, path: route.path };

		if (route.path === '/') {
			matchConfig.exact = true;
		}

		if (matchPath(location.pathname, matchConfig) && route.path !== '/*') {
			matchedRoutes.push(route.path);
		}

		if (route.routes) {
			getCurrentSelectedKeys(location, matchedRoutes, route.routes);
		}
	}
};
