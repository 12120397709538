import doc                  from 'decorators/doc';
import model                from 'decorators/model';
import { Timestampable }    from 'helpers/traits';
import { Blamable }         from 'helpers/traits';
import SalesPrivateApiModel from 'modelx/models/abstracts/SalesPrivateApiModel';

@model.staticLabel(`Provenance d'offre`, false)
@model.urnResource('origin')
@doc.path('/origins/{?id}')
export default class OriginModel extends Blamable(Timestampable(SalesPrivateApiModel)) {
	public _filters: ModelFiltersExtended<{
		'archived': '0' | '1';
		'createdAt[after]': string;
		'createdAt[before]': string;
		'createdAt[strictly_after]': string;
		'createdAt[strictly_before]': string;
		'label': string;
		'reference': string;
		'updatedAt[after]': string | Date;
		'updatedAt[before]': string | Date;
		'updatedAt[strictly_after]': string | Date;
		'updatedAt[strictly_before]': string | Date;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'createdAt': string;
		'id': string;
		'label': string;
		'reference': string;
		'updatedAt': string;
	}> = {};

	@doc.string declare label: string;
	@doc.string declare reference: string;
	@doc.boolean declare archived: boolean;
}