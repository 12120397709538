if (!window.FRONT_CONFIG) {
	window.FRONT_CONFIG = {
		APP_ENV: 'local',
		BACKOFFICES: [
			{
				icon: 'https://dev-siv2-qhs-fr.staging.stonecode.io/logos/logo-300-qhs.png',
				url: 'https://dev-siv2-qhs-fr.staging.stonecode.io',
				urn: '$:partition:partition:1',
			},
			{
				icon: 'https://dev-si-dicop-fr.staging.stonecode.io/logos/logo-300-dicop.png',
				url: 'https://dev-si-dicop-fr.staging.stonecode.io',
				urn: '$:partition:partition:2',
			},
			{
				icon: 'https://dev-si-rcontrolclim-fr.staging.stonecode.io/logos/logo-300-rcontrolclim.png',
				url: 'https://dev-si-rcontrolclim-fr.staging.stonecode.io',
				urn: '$:partition:partition:6',
			},
			{
				icon: 'https://dev-si-cg2d-fr.staging.stonecode.io/logos/logo-300-cg2d.png',
				url: 'https://dev-si-cg2d-fr.staging.stonecode.io',
				urn: '$:partition:partition:5',
			},
			{
				icon: 'https://dev-sia-compagnie-hpr-com.staging.stonecode.io/logos/logo-300-hpra.png',
				url: 'https://dev-sia-compagnie-hpr-com.staging.stonecode.io',
				urn: '$:partition:partition:4',
			},
			{
				icon: 'https://dev-si-compagnie-hpr-com.staging.stonecode.io/logos/logo-300-hpr.png',
				url: 'https://dev-si-compagnie-hpr-com.staging.stonecode.io',
				urn: '$:partition:partition:3',
			},
			{
				icon: 'https://dev-si-igienair-com.staging.stonecode.io/logos/logo-300-igienair.png',
				url: 'https://dev-si-igienair-com.staging.stonecode.io',
				urn: '$:partition:partition:7',
			},
			{
				icon: 'https://dev-si-adiams-com.staging.stonecode.io/logos/logo-300-adiams.png',
				url: 'https://dev-si-adiams-com.staging.stonecode.io',
				urn: '$:partition:partition:8',
			},
			{
				icon: 'https://dev-si-airsn-fr.staging.stonecode.io/logos/logo-300-airsn.png',
				url: 'https://dev-si-airsn-fr.staging.stonecode.io',
				urn: '$:partition:partition:9',
			},
			{
				icon: 'https://dev-si-mapclim-fr.staging.stonecode.io/logos/logo-300-mapclim.png',
				url: 'https://dev-si-mapclim-fr.staging.stonecode.io',
				urn: '$:partition:partition:10',
			},
			{
				icon: 'https://dev-si-d2h-ventilation-com.staging.stonecode.io/logos/logo-300-2dh.png',
				url: 'https://dev-si-d2h-ventilation-com.staging.stonecode.io',
				urn: '$:partition:partition:11',
			},
			{
				icon: 'https://dev-si-zaack-io.staging.stonecode.io/logos/logo-300-zaack.png',
				url: 'https://dev-si-zaack-io.staging.stonecode.io',
				urn: '$:partition:partition:12',
			},
			{
				icon: 'https://dev-si-nano-sense-com.staging.stonecode.io/logos/logo-300-nanosense.png',
				url: 'https://dev-si-nano-sense-com.staging.stonecode.io',
				urn: '$:partition:partition:13',
			},
			{
				icon: 'https://dev-si-addd-fr.staging.stonecode.io/logos/logo-300-addd.png',
				url: 'https://dev-si-addd-fr.staging.stonecode.io',
				urn: '$:partition:partition:14',
			},
			{
				icon: 'https://dev-si-agetep-com.staging.stonecode.io/logos/logo-300-agetep.png',
				url: 'https://dev-si-agetep-com.staging.stonecode.io',
				urn: '$:partition:partition:15',
			},
		],
		INSEE_SIREN_API_KEY: 'a42940bb-63a3-43ad-a940-bb63a3e3ad78',
		SENTRY_DSN_BACKOFFICE: '',
		SENTRY_DSN_FRONT_CONTACT: '',
		SENTRY_DSN_HEAD_OFFICE: '',
	};
}

const FrontConfig = window.FRONT_CONFIG;

export default FrontConfig;
