import ContactModel            from 'Models/supplier/ContactModel';
import SupplierEnterpriseModel from 'Models/supplier/SupplierEnterpriseModel';
import doc                     from 'decorators/doc';
import model                   from 'decorators/model';
import { Timestampable }       from 'helpers/traits';
import { Blamable }            from 'helpers/traits';
import SupplierPrivateApiModel from '../../abstracts/SupplierPrivateApiModel';

@model.staticLabel('Contact fournisseur')
@model.urnResource('supplier_enterprise_contact')
@doc.path('/supplier_enterprise_contacts/{?id}')
export default class SupplierEnterpriseContactModel extends Blamable(Timestampable(SupplierPrivateApiModel)) {
	public _filters: ModelFiltersExtended<{
		'contact': id;
		'contact.email': id;
		'contact.enabled': '0' | '1';
		'contact.function': id;
		'createdAt[after]': string;
		'createdAt[before]': string;
		'createdAt[strictly_after]': string;
		'createdAt[strictly_before]': string;
		'supplierEnterprise': id;
		'supplierEnterprise.supplierCompanies': id;
		'supplierEnterprise.supplierCompanies.supplierSubPartitions': id;
		'supplierEnterprise.supplierCompanies.supplierSubPartitions.ownerSubPartition.subPartitionUrn': Urn;
		'supplierEnterprise.supplierEnterpriseStatus': id;
		'supplierEnterprise.supplierEnterpriseStatus.reference': SupplierSupplierEnterpriseStatusReference;
		'supplierEnterprise.supplierPartitions': id;
		'supplierEnterprise.supplierPartitions.ownerPartition.partitionUrn': Urn;
		'updatedAt[after]': string | Date;
		'updatedAt[before]': string | Date;
		'updatedAt[strictly_after]': string | Date;
		'updatedAt[strictly_before]': string | Date;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'contact.email': string;
		'contact.function': string;
		'id': string;
		'updatedAt': string;
	}> = {};

	@doc.model(ContactModel) declare contact: ContactModel;
	@doc.model(SupplierEnterpriseModel) declare supplierEnterprise: SupplierEnterpriseModel;
}