import ColorValue         from 'components/ColorValue/ColorValue';
import GridInfoArrayValue from 'components/GridInfo/GridInfoArrayValue';
import GridInfoDateValue  from 'components/GridInfo/GridInfoDateValue';
import GridInfoEmptyValue from 'components/GridInfo/GridInfoEmptyValue';
import Link               from 'components/Link';
import SingleRowSkeleton  from 'components/SingleRowSkeleton';
import moment             from 'moment';
import { CSSProperties }  from 'react';
import React              from 'react';
import { formatPrice }    from 'tools/PriceHelper';
import formatPhoneNumber  from 'tools/formatPhoneNumber';

export type ValueType = 'default' | 'color' | 'date' | 'dateTime' | 'phone' | 'array' | 'boolean' | 'html' |
	'price' | 'email' | 'minutes' | 'percent' | 'preformatted';

export interface IValueProps {
	empty?: boolean;
	link?: string;
	linkTarget?: '_blank';
	loading?: boolean;
	style?: CSSProperties;
	type?: ValueType;
	value: React.ReactNode | JSX.Element | Moment;
}

export default class Value extends React.Component<IValueProps> {
	public render() {
		const { empty, link, linkTarget, loading, style, type, value } = this.props;

		if (empty) {
			return this._renderEmptyValue();
		}

		if (loading) {
			return <SingleRowSkeleton />;
		}

		if (!value && type !== 'boolean' && type !== 'price' && type !== 'minutes') {
			return this._renderEmptyValue();
		}

		const content = (
			<span className="Value" style={style}>
				{this._renderValueFromItem(this.props)}
			</span>
		);

		if (link) {
			return <Link target={linkTarget} to={link}>{content}</Link>;
		}

		return content;
	}

	private _renderEmptyValue = () => <GridInfoEmptyValue />;

	private _renderValueFromItem(item: IValueProps) {
		if (item.value instanceof moment) {
			return <GridInfoDateValue dateTime={item.type === 'dateTime'} value={item.value as string} />;
		}

		const value = item.value as React.ReactNode;

		switch (item.type) {
			case 'array':
				return (value as []).length ? <GridInfoArrayValue items={value as []} /> : this._renderEmptyValue();

			case 'color':
				return <ColorValue color={value?.toString()} />;

			case 'email':
				return <a href={`mailto:${value}`}>{value}</a>;

			case 'price':
				return <span>{formatPrice(value as number || 0)}</span>;

			case 'percent':
				return <span>{value}%</span>;

			case 'date':
				return <GridInfoDateValue value={value as string} />;

			case 'dateTime':
				return <GridInfoDateValue dateTime={true} value={value as string} />;

			case 'phone':
				return (
					<a href={`tel:${value}`}>
						{formatPhoneNumber(value as string)}
					</a>
				);

			case 'boolean':
				return value ? 'Oui' : 'Non';

			case 'html':
				return (
					<div className="typeHtml" dangerouslySetInnerHTML={{ __html: value?.toString() || '' }}>
					</div>
				);

			case 'minutes':
				return moment.utc((item.value as number) * 60 * 1000).format('LT');

			case 'preformatted':
				return <pre style={{ margin: 0 }}>{value}</pre>;

			default:
				return value;
		}
	}
}